<template>
  <component is="div">
    <b-tabs
      pills
    >
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">Khu vực ưu tiên</span>
        </template>
        <priority-zone
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">Đối tượng ưu tiên</span>
        </template>
        <confirmation
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">Thành tích học tập</span>
        </template>
        <certificate
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">Chứng chỉ ngoại ngữ</span>
        </template>
        <language-certificate
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import PriorityZone from '@/views/admission/admission-priority-score/PriorityZone.vue'
import Confirmation from '@/views/admission/admission-priority-score/Confirmation.vue'
import Certificate from '@/views/admission/admission-priority-score/Certificate.vue'
import LanguageCertificate from '@/views/admission/admission-priority-score/LanguageCertificate.vue'

export default {
  components: {
    BTab,
    BTabs,
    PriorityZone,
    Confirmation,
    Certificate,
    LanguageCertificate,
  },
}
</script>

<style>

</style>
